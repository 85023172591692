import React from "react";

const ServicesExtra = () => {
  return (
    <div className="ftco-hireme" id="services-extra">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-8 col-lg-8 d-flex align-items-center">
            <div className="w-100 py-4">
              <h2>Have a project on your mind?</h2>
              <p>
                Do you have a project proposal you woud like to develope? Fill
                out the form on the contact section with details of your project
                proposal and submit it to me. I will review your request
                information for your project and look forward to working with
                you.
              </p>
              <p className="mb-0">
                <a
                  onClick={() => {
                    const scrollDiv =
                      document.getElementById("contact-section").offsetTop;
                    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
                  }}
                  href
                  className="btn btn-primary py-3 px-4"
                >
                  Contact me
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 d-flex align-items-end">
            <img src="images/author.png" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ServicesExtra };
