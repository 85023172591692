import React, { useCallback, useContext, useEffect, useState } from "react";
import { DashboardContext, SET_ACTIVE_SCREEN } from "../../context/dashboard";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./App.css";
import Screen from "./Screen";

const DEFAULT_MAX_WIDTH = 350;

const Phone = ({ toggleDevice, devicePowered }) => {
  const { width } = useWindowDimensions();
  const mobileWidth =
    width >= DEFAULT_MAX_WIDTH ? width / 3 : DEFAULT_MAX_WIDTH;
  const [deviceWidth, setDeviceWidth] = useState(mobileWidth);
  const { dispatch } = useContext(DashboardContext);
  // const context = useContext(DashboardContext);

  const powerToggle = () => {
    // let { screen } = context.dashboardState;
    dispatch({
      type: SET_ACTIVE_SCREEN,
      payload: "lockscreen",
    });
    toggleDevice(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setScreen = useCallback((screen) => {
    dispatch({ type: SET_ACTIVE_SCREEN, payload: screen });
  });

  const increaseWidth = () => {
    let newWidth = deviceWidth + 30;

    if (newWidth >= 550) {
      return false;
    }

    setDeviceWidth(newWidth);
  };

  const decreseWidth = () => {
    let newWidth = deviceWidth - 30;

    if (newWidth <= DEFAULT_MAX_WIDTH) {
      newWidth = mobileWidth;
    }

    setDeviceWidth(newWidth);
  };

  useEffect(() => {
    setScreen("lockscreen");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="mobile-device"
      style={{
        display: devicePowered ? "block" : "none",
        maxWidth: deviceWidth,
      }}
    >
      <div className="mobile-device-inner loading-devic">
        <div className="mobile-device-back"></div>
        <div className="mobile-device-front">
          <div className="iphone social__phone">
            <div className="phone">
              <button
                data-toggle="modal"
                data-target="#guideModal"
                className="side-btn silent-btn"
              ></button>
              <button
                onClick={increaseWidth}
                className="side-btn vu-btn"
              ></button>
              <button
                onClick={decreseWidth}
                className="side-btn vd-btn"
              ></button>
              <button
                onClick={powerToggle}
                className="side-btn power-btn"
              ></button>
              <div className="phone-speakers">
                <div className="circle"></div>
                <div className="rectangle"></div>
              </div>
              <div className="screen">
                <div
                  id="mobileDeviceScreen"
                  className="content"
                  style={{
                    width: "100%",
                    height: "100%",
                    // zoom: "90%",
                    // backgroundColor: "#5a5a5a",
                    position: "relative",
                  }}
                >
                  <Screen setScreen={setScreen} />
                </div>
              </div>
              <div className="phone-bottom">
                <button
                  onClick={() => setScreen("home")}
                  onDoubleClickCapture={() => setScreen("lockscreen")}
                  className="home-button"
                >
                  <div className="button-box"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Phone };
