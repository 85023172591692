import React from "react";

const Skills = () => {
  return (
    <div className="ftco-section" id="skills-section">
      <div className="container">
        <div className="row justify-content-center pb-5">
          <div className="col-md-12 heading-section text-center ftco-animate">
            <span className="subheading">Skills</span>
            <h2 className="mb-4">My Skills</h2>
            <p>
              Talking about professional versatility? Here are my top skills
            </p>
          </div>
        </div>
        <div className="skills-slider owl-carousel">
          <div className="slider-item progress-circle col-lg-12 mb-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="h5 font-weight-bold text-center mb-4">Frontend</h2>

              <div className="progress mx-auto" data-value="98">
                <span className="progress-left">
                  <span className="progress-bar border-primary"></span>
                </span>
                <span className="progress-right">
                  <span className="progress-bar border-primary"></span>
                </span>
                <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                  <div className="h2 font-weight-bold">
                    98<sup className="small">%</sup>
                  </div>
                </div>
              </div>

              <div className="row text-center mt-4">
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">98%</div>
                  <span className="small text-gray">HTML</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">90%</div>
                  <span className="small text-gray">CSS</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">Javascript</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">89%</div>
                  <span className="small text-gray">Jquery</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">React JS</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">React Native</span>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-item progress-circle col-lg-12 mb-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="h5 font-weight-bold text-center mb-4">
                Version Control
              </h2>

              <div className="progress mx-auto" data-value="90">
                <span className="progress-left">
                  <span className="progress-bar border-primary"></span>
                </span>
                <span className="progress-right">
                  <span className="progress-bar border-primary"></span>
                </span>
                <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                  <div className="h2 font-weight-bold">
                    90<sup className="small">%</sup>
                  </div>
                </div>
              </div>

              <div className="row text-center mt-4">
                <div className="col-6 border-right">
                  <div className="h4 font-weight-bold mb-0">90%</div>
                  <span className="small text-gray">Github</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">90%</div>
                  <span className="small text-gray">Git Lab</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">Bitbucket</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">60%</div>
                  <span className="small text-gray">
                    Microsoft Team Foundation Server
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-item progress-circle col-lg-12 mb-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="h5 font-weight-bold text-center mb-4">Backend</h2>

              <div className="progress mx-auto" data-value="90">
                <span className="progress-left">
                  <span className="progress-bar border-primary"></span>
                </span>
                <span className="progress-right">
                  <span className="progress-bar border-primary"></span>
                </span>
                <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                  <div className="h2 font-weight-bold">
                    90<sup className="small">%</sup>
                  </div>
                </div>
              </div>

              <div className="row text-center mt-4">
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">95%</div>
                  <span className="small text-gray">PHP</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">Javascript</span>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-item progress-circle col-lg-12 mb-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="h5 font-weight-bold text-center mb-4">Database</h2>

              <div className="progress mx-auto" data-value="85">
                <span className="progress-left">
                  <span className="progress-bar border-primary"></span>
                </span>
                <span className="progress-right">
                  <span className="progress-bar border-primary"></span>
                </span>
                <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                  <div className="h2 font-weight-bold">
                    85<sup className="small">%</sup>
                  </div>
                </div>
              </div>

              <div className="row text-center mt-4">
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">98%</div>
                  <span className="small text-gray">MYSQL</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">Oracle RDBMS</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">90%</div>
                  <span className="small text-gray">PhpMyAdmin</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">70%</div>
                  <span className="small text-gray">PostgreSQL</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">60%</div>
                  <span className="small text-gray">SQLite</span>
                </div>

                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">MongoDB</span>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-item progress-circle col-lg-12 mb-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="h5 font-weight-bold text-center mb-4">UI/UX</h2>

              <div className="progress mx-auto" data-value="70">
                <span className="progress-left">
                  <span className="progress-bar border-primary"></span>
                </span>
                <span className="progress-right">
                  <span className="progress-bar border-primary"></span>
                </span>
                <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                  <div className="h2 font-weight-bold">
                    70<sup className="small">%</sup>
                  </div>
                </div>
              </div>

              <div className="row text-center mt-4">
                <div className="col-6 border-right">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">Sketch</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">90%</div>
                  <span className="small text-gray">InVision Studio</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">Adobe XD</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">80%</div>
                  <span className="small text-gray">Figma</span>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-item progress-circle col-lg-12 mb-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="h5 font-weight-bold text-center mb-4">SEO</h2>

              <div className="progress mx-auto" data-value="60">
                <span className="progress-left">
                  <span className="progress-bar border-primary"></span>
                </span>
                <span className="progress-right">
                  <span className="progress-bar border-primary"></span>
                </span>
                <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                  <div className="h2 font-weight-bold">
                    60<sup className="small">%</sup>
                  </div>
                </div>
              </div>

              <div className="row text-center mt-4">
                <div className="col-6 border-right">
                  <div className="h4 font-weight-bold mb-0">85%</div>
                  <span className="small text-gray">Google Search Console</span>
                </div>
                <div className="col-6">
                  <div className="h4 font-weight-bold mb-0">60%</div>
                  <span className="small text-gray">Moz Pro</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Skills };
