import React, { useState, useEffect, useCallback, useContext } from "react";
import { DashboardContext, SET_MUTED_SCREEN } from "../../context/dashboard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { ThreeCircles } from "react-loader-spinner";
import ICON from "../../assets/images/after-hours.png";
import { SECTIONS, AFTERHOURS } from "../../playlist";

const Player = ({ isActive }) => {
  const [audio, setAudio] = useState(null);
  const [selectedTrack, setSelectedTrack] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [sounds, setSounds] = useState([]);
  const { dispatch } = useContext(DashboardContext);

  const handleSectionScroll = useCallback(async () => {
    if (SECTIONS.includes(isActive)) {
      if (sounds.length === 0) {
        await toggleMuteVolume(false);
        selectTrack(AFTERHOURS?.tracks[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    handleSectionScroll();
  }, [handleSectionScroll]);

  const toggleMuteVolume = async (value) => {
    await dispatch({ type: SET_MUTED_SCREEN, payload: value });
  };

  const selectTrack = async (item) => {
    for (let index = 0; index < sounds.length; index++) {
      const element = sounds[index];
      element.pause();
    }
    await setIsLoading(false);
    const track = new Audio(item?.media);
    const { muted } = JSON.parse(localStorage.getItem("dashboard"));
    track.volume = muted ? 0 : 1;
    track.addEventListener(
      "loadstart",
      () => {
        setIsLoading(true);
      },
      false
    );
    track.addEventListener(
      "canplaythrough",
      async () => {
        await setIsLoading(false);
        setIsPlaying(true);
        track.play();
      },
      false
    );
    track.addEventListener(
      "ended",
      () => {
        if (canPlayNext(item)) {
          playNext(item);
        }
      },
      true
    );
    await setSelectedTrack({});
    await setSelectedTrack(item);
    setAudio(track);
    sounds.push(track);
    setSounds([...sounds, track]);
  };

  const toggleMuted = async () => {
    if (audio) {
      if (isMuted) {
        setIsMuted(false);
        await toggleMuteVolume(false);
        audio.volume = 1;
      } else {
        setIsMuted(true);
        await toggleMuteVolume(true);
        audio.volume = 0;
      }
    }
  };

  const playNext = (item) => {
    if (!item?.id) {
      return false;
    }

    if (!canPlayNext(item)) {
      return false;
    }

    const tracks = AFTERHOURS?.tracks;
    const nextId = Number(item?.id) + 1;
    const nextTrack = tracks.filter((itm) => itm.id === nextId);

    if (nextTrack[0]) {
      selectTrack(nextTrack[0]);
    }
  };

  const playPrev = (item) => {
    if (!item?.id) {
      return false;
    }

    if (!canPlayPrev(item)) {
      return false;
    }

    const tracks = AFTERHOURS?.tracks;
    const prevId = Number(item?.id) - 1;
    const prevTrack = tracks.filter((itm) => itm.id === prevId);

    if (prevTrack[0]) {
      selectTrack(prevTrack[0]);
    }
  };

  const canPlayNext = (current) => {
    if (!current?.id) {
      return false;
    }

    const tracks = AFTERHOURS?.tracks;
    const nextId = Number(current?.id) + 1;
    const nextTrack = tracks.filter((itm) => itm.id === nextId);

    if (nextTrack[0]) {
      return true;
    } else {
      return false;
    }
  };

  const canPlayPrev = (current) => {
    if (!current?.id) {
      return false;
    }

    const tracks = AFTERHOURS?.tracks;
    const prevId = Number(current?.id) - 1;
    const prevTrack = tracks.filter((itm) => itm.id === prevId);

    if (prevTrack[0]) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={`pilgirm-player mobile-hide-sm ${
        isActive !== "none" && "active"
      } ${isHidden && "hidden"}`}
    >
      <div
        className="mobile-toggle hover desktop-md-hide"
        onClick={() => {
          isHidden ? setIsHidden(false) : setIsHidden(true);
        }}
      >
        {isHidden ? (
          <FontAwesomeIcon icon={faAngleRight} />
        ) : (
          <FontAwesomeIcon icon={faAngleLeft} />
        )}
      </div>
      <div className="header hover">
        <div className="icon">
          <img src={ICON} alt="" />
        </div>
        <div className="info">
          <h4>{AFTERHOURS?.title}</h4>
          <p>{AFTERHOURS?.description}</p>
        </div>
      </div>
      <div className="content">
        <div className="controls">
          <div
            className="hover button"
            style={{ opacity: canPlayPrev(selectedTrack) ? 1 : 0.5 }}
            onClick={() => playPrev(selectedTrack)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div
            className="hover button progress-circle"
            onClick={() => toggleMuted()}
          >
            <div className="progress mx-auto" data-value="98">
              <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                <div className="play">
                  {isLoading ? (
                    <ThreeCircles
                      type="Oval"
                      color="#6c6c75"
                      height={20}
                      width={20}
                      style={{ marginTop: -3 }}
                    />
                  ) : isMuted ? (
                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faMicrophone} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="hover button"
            style={{ opacity: canPlayNext(selectedTrack) ? 1 : 0.5 }}
            onClick={() => playNext(selectedTrack)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
        <div className="list">
          <OwlCarousel
            className="owl-theme"
            items={1}
            dots={false}
            loop
            margin={0}
            nav={false}
            autoplay={true}
            autoplayHoverPause={true}
            autoplaySpeed={5000}
            startPosition={selectedTrack?.id ? selectedTrack?.id - 1 : 1}
          >
            {AFTERHOURS.tracks.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => selectTrack(item)}
                  className="item hover"
                >
                  <h4>{item?.id}</h4>
                  <p>{item?.title}</p>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
        {selectedTrack?.id ? (
          <div className="playing">
            <h4 className="mb-1">Now playing:</h4>
            <p>
              <b>Track:</b> {selectedTrack?.id} {selectedTrack?.title}
            </p>
            <hr className="mt-1 mb-1" />
            <p>
              <b>Duration:</b> {selectedTrack?.duration}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { Player };
