import {
  SET_ACTIVE_SCREEN,
  SET_VOLUME_SCREEN,
  SET_MUTED_SCREEN,
} from "./types";

// Reducer
export const dashboardReducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_SCREEN:
      return {
        ...state,
        screen: action.payload,
      };
    case SET_VOLUME_SCREEN:
      return {
        ...state,
        volume: action.payload,
      };
    case SET_MUTED_SCREEN:
      return {
        ...state,
        muted: action.payload,
      };
    default:
      return state;
  }
};
