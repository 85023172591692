export const SECTIONS = ["about", "skills", "services", "projects"];
export const AFTERHOURS = {
  id: 1,
  title: "The Weeknd - After Hours",
  author: "The Weeknd",
  description:
    "After Hours is the fourth studio album by Canadian singer the Weeknd, released on March 20, 2020, by XO and Republic Records.",
  icon: "",
  tracks: [
    {
      id: 1,
      title: "Alone Again",
      duration: "4:10",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/01%20Alone%20Again.mp3",
    },
    {
      id: 2,
      title: "Too Late",
      duration: "4:00",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/02%20Too%20Late.mp3",
    },
    {
      id: 3,
      title: "Hardest To Love",
      duration: "3:31",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/03%20Hardest%20To%20Love.mp3",
    },
    {
      id: 4,
      title: "Scared To Live",
      duration: "3:11",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/04%20Scared%20To%20Live.mp3",
    },
    {
      id: 5,
      title: "Snowchild",
      duration: "4:07",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/05%20Snowchild.mp3",
    },
    {
      id: 6,
      title: "Escape From LA",
      duration: "5:56",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/06%20Escape%20From%20LA.mp3",
    },
    {
      id: 7,
      title: "Heartless",
      duration: "3:18",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/07%20Heartless.mp3",
    },
    {
      id: 8,
      title: "Faith",
      duration: "4:43",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/08%20Faith.mp3",
    },
    {
      id: 9,
      title: "Blinding Lights",
      duration: "3:20",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/09%20Blinding%20Lights.mp3",
    },
    {
      id: 10,
      title: "In Your Eyes",
      duration: "3:58",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/10%20In%20Your%20Eyes.mp3",
    },
    {
      id: 11,
      title: "Save Your Tears",
      duration: "3:36",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/11%20Save%20Your%20Tears.mp3",
    },
    {
      id: 12,
      title: "Repeat After Me (Interlude)",
      duration: "3:16",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/12%20Repeat%20After%20Me%20(Interlude).mp3",
    },
    {
      id: 13,
      title: "After Hours",
      duration: "6:01",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/13%20After%20Hours.mp3",
    },
    {
      id: 14,
      title: "Until I Bleed Out",
      duration: "3:10",
      media:
        "https://github.com/GideonAgboba/Afterhours/raw/master/14%20Until%20I%20Bleed%20Out.mp3",
    },
  ],
};
