import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { faBatteryFull } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

function Statusbar({ color = "#6d6d769c" }) {
  // const [time, setTime] = useState(moment(moment.now()).format("h:mm"));

  // setInterval(() => {
  //   setTime(moment(moment.now()).format("h:mm"));
  // }, 1000);

  return (
    <div className="status-bar" style={{ color }}>
      <div>
        <span>{moment(moment.now()).format("h:mm")}</span>
      </div>
      <div className="d-flex align-items-center">
        <FontAwesomeIcon icon={faSignal} size="sm" />
        <span className="ml-1">4G</span>
        <FontAwesomeIcon icon={faBatteryFull} size="1x" className="ml-3" />
      </div>
    </div>
  );
}

export default Statusbar;
