import React, { useState, useRef } from "react";
import axios from "axios";
import { ThreeCircles } from "react-loader-spinner";
import { toast } from "react-toastify";

const URL = "https://pilgrim-server.herokuapp.com";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const nameInput = useRef(null);
  const emailInput = useRef(null);
  const subjectInput = useRef(null);
  const messageInput = useRef(null);

  const validate = () => {
    if (
      !nameInput ||
      nameInput.current.value !== "" ||
      !emailInput ||
      emailInput.current.value !== "" ||
      !subjectInput ||
      subjectInput.current.value !== "" ||
      !messageInput ||
      messageInput.current.value !== ""
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validate()) {
      toast("Please fill all fields", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${URL}/contact`, {
        name: nameInput.current.value,
        email: emailInput.current.value,
        subject: subjectInput.current.value,
        message: messageInput.current.value,
      });
      const { data } = response.data;

      if (data && data?._id) {
        toast("Message sent!", {
          position: "top-right",
          type: "success",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        nameInput.current.value = "";
        emailInput.current.value = "";
        subjectInput.current.value = "";
        messageInput.current.value = "";
      } else {
        toast("Oops!, Message failed to send", {
          position: "top-right",
          type: "warning",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    } catch (error) {
      toast("Something went wrong, sending message", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  return (
    <div
      className="ftco-section contact-section ftco-no-pb"
      id="contact-section"
    >
      <div className="fourth-overlay">
        <div className="overlay"></div>
      </div>
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 heading-section text-center ftco-animate">
            <span className="subheading">Contact us</span>
            <h2 className="mb-4">Have a Project?</h2>
            <p>
              Fill out the form below with details of your project proposal and
              submit it to me
            </p>
          </div>
        </div>

        <div className="row block-9">
          <div className="col-md-8">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="p-4 p-md-5 contact-form"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      required
                      type="text"
                      ref={nameInput}
                      className="form-control"
                      placeholder="Name/Company"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      required
                      type="email"
                      ref={emailInput}
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      ref={subjectInput}
                      className="form-control"
                      placeholder="Subject"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      required
                      ref={messageInput}
                      cols="30"
                      rows="7"
                      className="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      type="submit"
                      className={`btn btn-primary py-3 px-5 ${
                        loading ? "disabled" : ""
                      }`}
                    >
                      {loading ? (
                        <ThreeCircles
                          type="Oval"
                          color="#fff"
                          height={20}
                          width={20}
                          style={{ marginTop: -3 }}
                        />
                      ) : (
                        "Send Message"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="col-md-4 d-flex pl-md-5">
            <div className="row pt-2">
              <div className="dbox w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-map-marker"></span>
                </div>
                <div className="text">
                  {/* <p>
                    <span>Address:</span> 443 Herbert Macaulay Way, Abule ijesha
                    100001, Kyrenia, Cyprue
                  </p> */}
                  <p>
                    <span>Address:</span> Z07 Akacan Elegance, Giren 99300,
                    Cyprus
                  </p>
                </div>
              </div>
              <div className="dbox w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-phone"></span>
                </div>
                <div className="text">
                  <p>
                    <span>Phone:</span>{" "}
                    <a href="tel://+90538880832">
                      +90538880832, +2348025668718
                    </a>
                  </p>
                </div>
              </div>
              <div className="dbox w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-paper-plane"></span>
                </div>
                <div className="text">
                  <p>
                    <span>Email:</span>{" "}
                    <a target="blank" href="mailto:pilgrim.neu@gmail.com">
                      pilgrim.neu@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Contact };
