/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { DashboardContextProvider } from "./context/dashboard";
import {
  Nav,
  Home,
  HomeExtra,
  About,
  Skills,
  Services,
  ServicesExtra,
  Projects,
  Contact,
  Footer,
  Donate,
  Guide,
  Tips,
  Player,
  Phone,
  Control,
} from "./components";
import "./assets/css/App.css";
import "./assets/css/Responsive.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  const [isReady, setIsReady] = useState(false);
  const [devicePowered, setDevicePowered] = useState(false);
  const [activeSection, setActiveSection] = useState(false);
  const [canViewProjects, setCanViewProjects] = useState(false);

  useEffect(() => {
    let gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `/assets/js/main.js`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);

    document.addEventListener("scroll", trackScrolling);
    document.body.addEventListener("click", () => {});

    return () => {
      document.removeEventListener("scroll", trackScrolling);
      document.body.removeEventListener("click", () => {});
    };
  }, []);

  const exploreMe = () => {
    const scrollDiv = document.getElementById("section-counter").offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
    document.body.style.overflowY = "auto";
  };

  const contactMe = () => {
    const scrollDiv = document.getElementById("contact-section").offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
    document.body.style.overflowY = "auto";
  };

  const toggleDevice = (value = true) => {
    setDevicePowered(value);
  };

  const trackScrolling = useCallback(() => {
    // about
    const aboutRef = document.getElementById("about-section");
    if (isInViewport(aboutRef)) {
      if (activeSection !== "about") {
        setActiveSection("about");
      }
    }

    // skills
    const skillsRef = document.getElementById("skills-section");
    if (isInViewport(skillsRef)) {
      if (activeSection !== "skills") {
        setActiveSection("skills");
      }
    }

    // services
    const servicesRef = document.getElementById("services-section");
    if (isInViewport(servicesRef)) {
      if (activeSection !== "services") {
        setActiveSection("services");
      }
    }

    // projects
    const projectsRef = document.getElementById("projects-section");
    if (isInViewport(projectsRef)) {
      if (activeSection !== "projects") {
        setActiveSection("projects");
        setCanViewProjects(true);
      }
    }

    // none
    if (
      !isInViewport(aboutRef) &&
      !isInViewport(skillsRef) &&
      !isInViewport(servicesRef) &&
      !isInViewport(projectsRef)
    ) {
      if (activeSection !== "none") {
        setActiveSection("none");
      }
    }
  });

  const isInViewport = (element) => {
    let position = element.getBoundingClientRect();
    // checking for partial visibility
    if (position.top < window.innerHeight && position.bottom >= 0) {
      return true;
    }

    return false;
  };

  return (
    <DashboardContextProvider>
      <ToastContainer />
      <Control
        isReady={isReady}
        toggleDevice={toggleDevice}
        devicePowered={devicePowered}
      />
      <Phone toggleDevice={toggleDevice} devicePowered={devicePowered} />
      <Player isActive={activeSection} />
      <Donate />
      <Guide />
      <Tips />

      <Nav isReady={isReady} setIsReady={setIsReady} />

      <Home exploreMe={exploreMe} contactMe={contactMe} />

      <HomeExtra />

      <About isActive={activeSection === "about" ? true : false} />

      <Skills isActive={activeSection === "skills" ? true : false} />

      <Services isActive={activeSection === "services" ? true : false} />

      <ServicesExtra />

      <Projects
        canViewProjects={canViewProjects}
        isActive={activeSection === "projects" ? true : false}
      />

      <Contact />

      {/* {isReady ? (
        <Bubbles
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            opacity: activeSection === "none" ? 0 : 1,
          }}
          color={"10119,11,19"}
        />
      ) : null} */}
      <Footer />
    </DashboardContextProvider>
  );
}

export default App;
