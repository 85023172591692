import React from "react";

const HomeExtra = () => {
  return (
    <div className="ftco-counter img" id="section-counter">
      <div className="container">
        <div className="row">
          <div className="col-md-3 justify-content-center counter-wrap ftco-animate">
            <div className="block-18 d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="flaticon-suitcase"></span>
              </div>
              <div className="text">
                <strong className="number" data-number="23">
                  0
                </strong>
                <span>Project Complete</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 justify-content-center counter-wrap ftco-animate">
            <div className="block-18 d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="flaticon-loyalty"></span>
              </div>
              <div className="text">
                <strong className="number" data-number="23">
                  0
                </strong>
                <span>Happy Clients</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 justify-content-center counter-wrap ftco-animate">
            <div className="block-18 d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="flaticon-coffee"></span>
              </div>
              <div className="text">
                <strong className="number" data-number="200">
                  0
                </strong>
                <span>Cups of coffee</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 justify-content-center counter-wrap ftco-animate">
            <div className="block-18 d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="flaticon-calendar"></span>
              </div>
              <div className="text">
                <strong className="number" data-number="8">
                  0
                </strong>
                <span>Years experienced</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HomeExtra };
