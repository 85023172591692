import React from "react";
import LockscreenImage from "../../../assets/images/lockscreen.png";
import "../../../assets/css/Lockscreen.css";

import Statusbar from "../props/Statusbar";

import moment from "moment";

function Lockscreen({ setScreen }) {
  return (
    <div
      className="home-screen"
      style={{
        backgroundImage: `url(${LockscreenImage})`,
        backgroundSize: "50%",
        // backgroundImage: `linear-gradient(40deg, rgb(0 0 0 / 28%), rgb(0 0 0 / 28%), rgb(0 0 0 / 28%)), url(${LockscreenImage})`,
        overflow: "hidden",
      }}
    >
      <Statusbar />
      <div className="lockscreen-time">
        <h4>
          {moment(moment.now()).format("dddd")},{" "}
          {moment(moment.now()).format("DD MMMM")}
        </h4>
        <span>Click the home button to unlock device</span>
        <br />
        <small data-toggle="modal" data-target="#guideModal">
          Click to view <b className="text-info hover">User Guide</b>
        </small>
      </div>
    </div>
  );
}

export default Lockscreen;
