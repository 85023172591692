import React, { useState, useEffect, useContext } from "react";
import { DashboardContext } from "../../context/dashboard";

import Lockscreen from "./screens/Lockscreen";
import Home from "./screens/Home";
import Phone from "./screens/Phone";
import Music from "./screens/Music";
import Pacman from "./screens/Pacman";

function Screen({ setScreen }) {
  const [currentScreen, setCurrentScreen] = useState(<></>);
  const context = useContext(DashboardContext);

  const renderScreen = () => {
    const { screen } = context.dashboardState;

    switch (screen) {
      case "home":
        return <Home setScreen={setScreen} />;
      case "lockscreen":
        return <Lockscreen setScreen={setScreen} />;
      case "phone":
        return <Phone setScreen={setScreen} />;
      case "music":
        return <Music setScreen={setScreen} />;
      case "pacman":
        return <Pacman setScreen={setScreen} />;
      default:
        return <Lockscreen setScreen={setScreen} />;
    }
  };

  useEffect(() => {
    setCurrentScreen(renderScreen());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return currentScreen;
}

export default Screen;
