import moment from "moment";
import React from "react";

const Footer = () => {
  return (
    <footer className="ftco-footer ftco-section" id="footer-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-3">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Lets talk funding</h2>
              <p>
                Want to be a major contributor to Pilgrim's projects? Click the
                button below. Your admirable action will forever stay in my
                heart.
              </p>
              <p>
                <a
                  href
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#donateModal"
                >
                  Donate Now
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="ftco-footer-widget mb-4 ml-md-4">
              <h2 className="ftco-heading-2">Links</h2>
              <ul className="list-unstyled">
                <li>
                  <a href="#root">
                    <span className="fa fa-chevron-right mr-2"></span>Home
                  </a>
                </li>
                <li>
                  <a href="#about-section">
                    <span className="fa fa-chevron-right mr-2"></span>About
                  </a>
                </li>
                <li>
                  <a href="#services-section">
                    <span className="fa fa-chevron-right mr-2"></span>Services
                  </a>
                </li>
                <li>
                  <a href="#projects-section">
                    <span className="fa fa-chevron-right mr-2"></span>Projects
                  </a>
                </li>
                <li>
                  <a href="#contact-section">
                    <span className="fa fa-chevron-right mr-2"></span>Contact
                  </a>
                </li>
                <li>
                  <a href data-toggle="modal" data-target="#tipsModal">
                    <span className="fa fa-chevron-right mr-2"></span>Website
                    Tips
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Services</h2>
              <ul className="list-unstyled">
                <li>
                  <a href="#services-section">
                    <span className="fa fa-chevron-right mr-2"></span>Web Design
                  </a>
                </li>
                <li>
                  <a href="#services-section">
                    <span className="fa fa-chevron-right mr-2"></span>Web
                    Development
                  </a>
                </li>
                <li>
                  <a href="#services-section">
                    <span className="fa fa-chevron-right mr-2"></span>Branding
                  </a>
                </li>
                <li>
                  <a href="#services-section">
                    <span className="fa fa-chevron-right mr-2"></span>Graphic
                    Design
                  </a>
                </li>
                <li>
                  <a href="#services-section">
                    <span className="fa fa-chevron-right mr-2"></span>SEO
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Have a Questions?</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <a href>
                      <span className="icon fa fa-map marker"></span>
                      <span className="text">Kyrenia, Cyprue</span>
                      {/* <span className="text">
                        443 Herbert Macaulay Way, Abule ijesha 100001, Lagos,
                        Nigeria
                      </span> */}
                    </a>
                  </li>
                  <li>
                    <a href>
                      <span className="icon fa fa-phone"></span>
                      <span className="text">+234 9069376786</span>
                    </a>
                  </li>
                  <li>
                    <a href>
                      <span className="icon fa fa-paper-plane pr-4"></span>
                      <span className="text">pilgrim.neu@gmail.com</span>
                    </a>
                  </li>
                </ul>
              </div>
              <ul className="ftco-footer-social list-unstyled mt-2">
                {/* <li className="ftco-animate">
                  <a
                    href="https://twitter.com/kilmkimmy2000?s=08"
                    target="blank"
                  >
                    <span className="text-white fa fa-twitter"></span>
                  </a>
                </li> */}
                {/* <li className="ftco-animate">
                  <a
                    href="https://web.facebook.com/profile.php?id=100071032781433"
                    target="blank"
                  >
                    <span className="text-white fa fa-facebook"></span>
                  </a>
                </li> */}
                <li className="ftco-animate">
                  <a
                    href="https://www.linkedin.com/in/agboba-gideon-40457116b/"
                    target="blank"
                  >
                    <span className="text-white fa fa-linkedin"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="https://github.com/GideonAgboba" target="blank">
                    <span className="text-white fa fa-github"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            {/* Copyright &copy;  */}
            <p>Pilgrim&copy;{moment(new Date()).format("YYYY")}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
