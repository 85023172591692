import React from "react";

const Services = () => {
  return (
    <div className="ftco-section" id="services-section">
      <div className="container">
        <div className="third-overlay">
          <div className="overlay"></div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 heading-section text-center ftco-animate mb-5">
            <span className="subheading">I am Pilgrim</span>
            <h2 className="mb-4">Giving awesome services to my clients</h2>
            <p>Explore my services</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="media block-6 services d-block bg-white rounded-lg shadow ftco-animate">
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="flaticon-3d-design"></span>
              </div>
              <div className="media-body">
                <h3 className="heading mb-3">Web Design</h3>
                <p>
                  Get designs focusing on users experience aspects of website
                  development
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="media block-6 services d-block bg-white rounded-lg shadow ftco-animate">
              <div className="icon shadow d-flex align-items-center justify-content-center">
                <span className="flaticon-app-development"></span>
              </div>
              <div className="media-body">
                <h3 className="heading mb-3">Web Application</h3>
                <p>
                  Build standard Client-side and Server-side software
                  application
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="media block-6 services d-block bg-white rounded-lg shadow ftco-animate">
              <div className="icon shadow d-flex align-items-center justify-content-center">
                <span className="flaticon-web-programming"></span>
              </div>
              <div className="media-body">
                <h3 className="heading mb-3">Web Development</h3>
                <p>
                  Develope amazing websites for local or online applications
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="media block-6 services d-block bg-white rounded-lg shadow ftco-animate">
              <div className="icon shadow d-flex align-items-center justify-content-center">
                <span className="flaticon-computer"></span>
              </div>
              <div className="media-body">
                <h3 className="heading mb-3">Branding</h3>
                <p>
                  Model and market your brand to get amazing increase on your
                  brand audience
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="media block-6 services d-block bg-white rounded-lg shadow ftco-animate">
              <div className="icon shadow d-flex align-items-center justify-content-center">
                <span className="flaticon-vector"></span>
              </div>
              <div className="media-body">
                <h3 className="heading mb-3">Graphic Design</h3>
                <p>
                  Create visual content to communicate ideas that inspire,
                  inform, and captivate consumers
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="media block-6 services d-block bg-white rounded-lg shadow ftco-animate">
              <div className="icon shadow d-flex align-items-center justify-content-center">
                <span className="flaticon-zoom"></span>
              </div>
              <div className="media-body">
                <h3 className="heading mb-3">SEO</h3>
                <p>
                  Rank your online platforms higher in search engines such as
                  Google
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Services };
