import React from "react";
import IMGONE from "../assets/images/Saly-41.png";

const About = () => {
  return (
    <div
      className="ftco-about ftco-section ftco-no-pt ftco-no-pb"
      id="about-section"
    >
      <div className="container">
        <div className="row d-flex no-gutters">
          <div className="col-md-6 col-lg-5 d-flex">
            <img className="img-fluid" src={`${IMGONE}`} alt="" />
          </div>
          <div className="col-md-6 col-lg-7 pl-md-4 pl-lg-5 py-5">
            <div className="second-overlay">
              <div className="overlay"></div>
              <div className="overlay-1"></div>
            </div>
            <div className="py-md-5">
              <div className="row justify-content-start pb-3">
                <div className="col-md-12 heading-section ftco-animate">
                  <span className="subheading">My Intro</span>
                  <h2
                    className="mb-4"
                    // style="font-size: 34px; text-transform: capitalize;"
                  >
                    About Gideon
                  </h2>
                  <p>
                    Gideon is an open-minded Software Developer & Entrepreneur.
                    As a full-stack web/mobile developer, UI/UX & javascript
                    specialist with an amazing 8+ years development experience.
                    I've worked on brilliant projects both solo & in a team,
                    with remarkable testimonials from clients and end-users.
                  </p>

                  <ul className="about-info mt-4 px-md-0 px-2">
                    <li className="d-flex">
                      <span>Name:</span> <span>Agboba Gideon Sunshine</span>
                    </li>
                    <li className="d-flex">
                      <span>Gender:</span> <span>Male</span>
                    </li>
                    <li className="d-flex">
                      <span>Address:</span>{" "}
                      <span>Z07 Akacan Elegance, Giren 99300, Cyprus</span>
                      {/* <span>21 road, Ipaja, Kyrenia, Cyprue</span> */}
                    </li>
                    <li className="d-flex">
                      <span>Email:</span> <span>pilgrim.neu@gmail.com</span>
                    </li>
                    <li className="d-flex">
                      <span>Phone: </span>{" "}
                      <span>+2348025668718, +90538880832</span>
                    </li>
                    {/* <li className="d-flex">
                      <span>Relationship Status:</span> <span>Complicated</span>
                    </li> */}
                    <li className="d-flex">
                      <span>Personality:</span> <span>Ambivert</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <div className="my-interest d-lg-flex w-100">
                    <div className="interest-wrap d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="flaticon-listening"></span>
                      </div>
                      <div className="text">Music</div>
                    </div>
                    <div className="interest-wrap d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="flaticon-suitcases"></span>
                      </div>
                      <div className="text">Travel</div>
                    </div>
                    <div className="interest-wrap d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="flaticon-video-player"></span>
                      </div>
                      <div className="text">Movie</div>
                    </div>
                    <div className="interest-wrap d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="flaticon-football"></span>
                      </div>
                      <div className="text">Sports</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { About };
