import React from "react";

function Pacman({ setScreen }) {
  return (
    <iframe
      title="pacman"
      src="./games/pacman/index.htm"
      frameborder="0"
    ></iframe>
  );
}

export default Pacman;
