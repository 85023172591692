import React from "react";
import HOMEGUIDE from "../assets/images/guide.png";

const Guide = () => {
  return (
    <div
      className="modal fade"
      id="guideModal"
      tabindex={-1}
      role="dialog"
      aria-labelledby="guideModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="modal-content"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="guideModalLabel">
              User Device Guide
            </h5>
          </div>
          <div className="modal-body p-0">
            <div id="accordion">
              <div className="card">
                <div
                  className="card-header hover"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  id="headingOne"
                >
                  <h6 className="mb-0">Controls</h6>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body text-center">
                    <img src={HOMEGUIDE} alt="guide" className />
                  </div>
                </div>
              </div>
              <div className="card">
                <div
                  className="card-header hover"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  id="headingTwo"
                >
                  <h6 className="mb-0">Phone</h6>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Seemless dial-pad to contact inputed number within device
                  </div>
                </div>
              </div>
              <div className="card">
                <div
                  className="card-header hover"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  id="headingThree"
                >
                  <h6 className="mb-0">Music</h6>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Play your favourite songs with extra comfort within device
                  </div>
                </div>
              </div>
              <div className="card">
                <div
                  className="card-header hover"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  id="headingFour"
                >
                  <h6 className="mb-0">Pacman</h6>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Play the legendary <b>pacman</b> within device
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { Guide };
