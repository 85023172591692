import React, { useState } from "react";
import { PaystackConsumer } from "react-paystack";
import { toast } from "react-toastify";

const Donate = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(1000);

  const validated = () => {
    if (name !== "" && email !== "" && amount !== "" && amount >= 1000) {
      return true;
    }

    return false;
  };

  const handleSuccess = (ref) => {
    toast(
      `Donation Complete!🥳  Thanks ${name.toUpperCase()}❤️🙃, your donation is highly valued`,
      {
        position: "top-right",
        type: "success",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleClose = () => {
    toast("You canceled the donation😳", {
      position: "top-right",
      type: "error",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div
      className="modal fade"
      id="donateModal"
      tabindex={-1}
      role="dialog"
      aria-labelledby="donateModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="modal-content contact-section"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="donateModalLabel">
              Lets talk funding
            </h5>
          </div>
          <div className="modal-body contact-form">
            <p>
              Want to be a major contributor to Pilgrim's projects? Click the
              button below. Your admirable action will forever stay in my heart.
            </p>
            <div className="row">
              <div className="col-md-5">
                <div className="form-group mt-2">
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Enter Fullname"
                    onInput={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group mt-2">
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    onInput={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <input
                    required
                    type="number"
                    min={1000}
                    className="form-control"
                    placeholder={`Enter Amount Above ₦${amount}`}
                    onInput={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Close
            </button>
            <PaystackConsumer
              {...{
                reference: new Date().getTime(),
                email: email,
                amount: amount * 100, // naira
                publicKey: "pk_live_b90875cc98680c171ebd5003a9308317d81c61b9",
                text: `Pilgrim donation from ${name}`,
                onSuccess: (reference) => handleSuccess(reference),
                onClose: () => handleClose(),
              }}
            >
              {({ initializePayment }) => (
                <button
                  type="submit"
                  onClick={() => {
                    if (!validated()) {
                      return false;
                    }
                    initializePayment(handleSuccess, handleClose);
                  }}
                  className="btn btn-primary"
                >
                  Donate ₦{amount}
                </button>
              )}
            </PaystackConsumer>
          </div>
        </form>
      </div>
    </div>
  );
};

export { Donate };
