import React from "react";
import IMGONE from "../assets/images/Saly-40.png";
import JUSTRITE from "../assets/images/justrite.png";
import KAFFY from "../assets/images/kaffy.png";
import KOHINOOR from "../assets/images/kohinoor.png";
import ODSGMOE from "../assets/images/odsgmoe.png";
import VFD from "../assets/images/vfd.png";
import CWR from "../assets/images/cwr.png";

const Projects = ({ canViewProjects, isActive }) => {
  return (
    <div className="ftco-section ftco-project" id="projects-section">
      <div className="fourth-overlay">
        <div className="overlay"></div>
      </div>
      <div className="container-fluid px-md-4">
        <div className="row justify-content-center pb-5">
          <div className="col-md-12 heading-section text-center ftco-animate">
            <span className="subheading">Accomplishments</span>
            <h2 className="mb-4">My Projects</h2>
            <p>Here are my top 6 recent Mobile & Web projects</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="projects-slider owl-carousel">
              <div className="slider-item">
                <div className="project ftco-animate d-flex justify-content-center align-items-center">
                  <div className="text text-center">
                    <div>
                      <h3>
                        <a href className="project-header">
                          <b>01</b> Justrite
                          <img alt="" src={`${JUSTRITE}`} />
                        </a>
                      </h3>
                      <small>
                        <b>Mobile</b>
                      </small>
                      <br />
                      <span>E-commerce application</span>
                    </div>
                    <div>
                      {canViewProjects ? (
                        <iframe
                          title="app"
                          src="https://appetize.io/embed/3b8h0m8ukbjaw3jpy2gcbf3fym?device=iphone8"
                          height="600px"
                          frameBorder="0"
                          scrolling="no"
                          style={{ borderRadius: 20 }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="slider-item">
                <div className="project ftco-animate d-flex justify-content-center align-items-center">
                  <div className="text text-center">
                    <div>
                      <h3>
                        <a href className="project-header">
                          <b>02</b> Kaffy Inc
                          <img alt="" src={`${KAFFY}`} />
                        </a>
                      </h3>
                      <small>
                        <b>Mobile</b>
                      </small>
                      <br />
                      <span>Multi-purpose application</span>
                    </div>
                    <div>
                      {canViewProjects ? (
                        <iframe
                          title="app"
                          src="https://appetize.io/embed/2b54mf9247y8thpmd0t0mx07d0?device=iphone8"
                          height="600px"
                          frameBorder="0"
                          scrolling="no"
                          style={{ borderRadius: 20 }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="slider-item">
                <div className="project ftco-animate d-flex justify-content-center align-items-center">
                  <div className="text text-center">
                    <div>
                      <h3>
                        <a href className="project-header">
                          <b>03</b> Kohinoor Go
                          <img alt="" src={`${KOHINOOR}`} />
                        </a>
                      </h3>
                      <small>
                        <b>Mobile</b>
                      </small>
                      <br />
                      <span>E-commerce application</span>
                    </div>
                    <div>
                      {canViewProjects ? (
                        <iframe
                          title="app"
                          src="https://appetize.io/embed/guvzby1vnrcqh6eq5guqamuxng?device=iphone8"
                          height="600px"
                          frameBorder="0"
                          scrolling="no"
                          style={{ borderRadius: 20 }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="slider-item">
                <div className="project ftco-animate d-flex justify-content-center align-items-center">
                  <div className="text text-center">
                    <div>
                      <h3>
                        <a href className="project-header">
                          <b>04</b> ODSGMOE Attendance
                          <img alt="" src={`${ODSGMOE}`} />
                        </a>
                      </h3>
                      <small>
                        <b>Mobile</b>
                      </small>
                      <br />
                      <span>Attendance application for ondo state</span>
                    </div>
                    <div>
                      {canViewProjects ? (
                        <iframe
                          title="app"
                          src="https://appetize.io/embed/705xnfa4w7qhj5gyer23aatrhw?device=iphone8"
                          height="600px"
                          frameBorder="0"
                          scrolling="no"
                          style={{ borderRadius: 20 }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="slider-item">
                <div className="project ftco-animate d-flex justify-content-center align-items-center">
                  <div className="text text-center">
                    <div>
                      <h3>
                        <a href className="project-header">
                          <b>05</b> Inner City Mission VFD
                          <img alt="" src={`${VFD}`} />
                        </a>
                      </h3>
                      <small>
                        <b>Web</b>
                      </small>
                      <br />
                      <span>Food campaign platform</span>
                    </div>
                    <div>
                      {canViewProjects ? (
                        <iframe
                          title="app"
                          src="https://vfd.theinnercitymission.ngo/"
                          height="600px"
                          frameBorder="0"
                          scrolling="no"
                          style={{ borderRadius: 20 }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="slider-item">
                <div className="project ftco-animate d-flex justify-content-center align-items-center">
                  <div className="text text-center">
                    <div>
                      <h3>
                        <a href className="project-header">
                          <b>06</b> CWR Lagos
                          <img alt="" src={`${CWR}`} />
                        </a>
                      </h3>
                      <small>
                        <b>Web</b>
                      </small>
                      <br />
                      <span>Property listing platform</span>
                    </div>
                    <div>
                      {canViewProjects ? (
                        <iframe
                          title="app"
                          src="https://cwlagoslistings.com"
                          height="600px"
                          frameBorder="0"
                          scrolling="no"
                          style={{ borderRadius: 20 }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mobile-sm-hide">
            <img className="img-fluid" src={`${IMGONE}`} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Projects };
