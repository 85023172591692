import React from "react";

const Tips = () => {
  return (
    <div
      className="modal fade"
      id="tipsModal"
      tabindex={-1}
      role="dialog"
      aria-labelledby="tipsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="modal-content"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="tipsModalLabel">
              Website Tips
            </h5>
          </div>
          <div className="modal-body">
            <div className="tips">
              <h6>Tips:</h6>
              <ul>
                <li>
                  <small className="text-muted">
                    Click the <b>"Explore Me"</b> button to begin surfing
                  </small>
                </li>
                <li>
                  <small className="text-muted">
                    Click the <b>"Cancle Audio"</b> icon on the music player to
                    toggle audio mute
                  </small>
                </li>
                <li>
                  <small className="text-muted">
                    Click the <b>"Phone"</b> icon at the bottom-right to toggle
                    virtual phone
                  </small>
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { Tips };
