import React from "react";
import TypeIt from "typeit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import ICON from "../assets/images/logo.gif";

const Nav = ({ isReady, setIsReady }) => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light site-navbar-target"
      id="ftco-navbar"
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={ICON} alt="" />
          <TypeIt
            options={{
              strings: ["Pilgrim"],
              cursor: false,
              loop: false,
              speed: 150,
              afterComplete: () => {
                if (!isReady) {
                  setTimeout(() => {
                    setIsReady(true);
                  }, 1000);
                }
              },
            }}
          />
          <span className="stop">.</span>
        </a>
        <button
          className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu"></span>{" "}
          <FontAwesomeIcon icon={faAlignJustify} style={{ fontSize: 25 }} />
        </button>

        <div className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav nav ml-auto">
            <li className="nav-item">
              <a href="#home-section" className="nav-link">
                <span>Home</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#about-section" className="nav-link">
                <span>About</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#skills-section" className="nav-link">
                <span>Skills</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#services-section" className="nav-link">
                <span>Services</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#projects-section" className="nav-link">
                <span>Projects</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#contact-section" className="nav-link">
                <span>Contact</span>
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                More
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  href
                  data-toggle="modal"
                  data-target="#guideModal"
                  className="dropdown-item"
                >
                  User Device Guide
                </a>
                <a
                  href
                  data-toggle="modal"
                  data-target="#tipsModal"
                  className="dropdown-item"
                >
                  Website Tips
                </a>
                <div className="dropdown-divider"></div>
                <a
                  href
                  data-toggle="modal"
                  data-target="#donateModal"
                  className="dropdown-item"
                >
                  Donate
                </a>
                <div className="dropdown-divider"></div>
                {/* <a
                  href="https://twitter.com/kilmkimmy2000?s=08"
                  target="blank"
                  className="dropdown-item"
                >
                  Twitter
                </a>
                <a
                  href="https://web.facebook.com/profile.php?id=100071032781433"
                  target="blank"
                  className="dropdown-item"
                >
                  Facebook
                </a>
                <a
                  href="https://www.instagram.com/pilgrim.neu/"
                  target="blank"
                  className="dropdown-item"
                >
                  Instagram
                </a> */}
                <a
                  href="https://www.linkedin.com/in/agboba-gideon-40457116b/"
                  target="blank"
                  className="dropdown-item"
                >
                  Linkedin
                </a>
                <a
                  href="https://github.com/GideonAgboba"
                  target="blank"
                  className="dropdown-item"
                >
                  Github
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export { Nav };
