import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faHeadphones,
  faCamera,
  faImages,
  faFolder,
  faBook,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import Packman from "../../../assets/images/pacman.png";
import HomeImage from "../../../assets/images/home.png";
import "../../../assets/css/Home.css";

import Statusbar from "../props/Statusbar";

function Home({ setScreen }) {
  const apps = [
    {
      title: "phone",
      icon: <FontAwesomeIcon icon={faPhone} />,
      iconColor: "#fff",
      iconBgColor:
        "repeating-linear-gradient(201deg, #4caf50, rgb(44, 44, 52) 100px)",
      onClick: () => {
        // alert("Phone is unavailable for now. You can play Pacman🙃");
        setScreen("phone");
      },
    },
    {
      title: "music",
      icon: <FontAwesomeIcon icon={faHeadphones} />,
      iconColor: "#fff",
      iconBgColor:
        "repeating-linear-gradient(201deg, #2196f3, rgb(44, 44, 52) 100px)",
      onClick: () => {
        // alert("Music is unavailable for now. You can play Pacman🙃");
        setScreen("music");
      },
    },
    {
      title: "camera",
      icon: <FontAwesomeIcon icon={faCamera} />,
      iconColor: "#5a5a5a",
      iconBgColor:
        "repeating-linear-gradient(201deg, lightgrey, rgb(44, 44, 52) 100px)",
      onClick: () => {
        alert("Camera is unavailable for now. You can play Pacman🙃");
        // setScreen("message")
      },
    },
    {
      title: "gallery",
      icon: <FontAwesomeIcon icon={faImages} />,
      iconColor: "#fff",
      iconBgColor:
        "repeating-linear-gradient(201deg, #9c27b0, rgb(44, 44, 52) 100px)",
      onClick: () => {
        alert("Gallery is unavailable for now. You can play Pacman🙃");
        // setScreen("message")
      },
    },
    {
      title: "files",
      icon: <FontAwesomeIcon icon={faFolder} />,
      iconColor: "#fff",
      iconBgColor:
        "repeating-linear-gradient(201deg, #009688, rgb(44, 44, 52) 100px)",
      onClick: () => {
        alert("Files is unavailable for now. You can play Pacman🙃");
        // setScreen("message")
      },
    },
    {
      title: "notes",
      icon: <FontAwesomeIcon icon={faBook} />,
      iconColor: "#fff",
      iconBgColor:
        "repeating-linear-gradient(201deg, #ffc107, rgb(44, 44, 52) 100px)",
      onClick: () => {
        alert("Notes is unavailable for now. You can play Pacman🙃");
        // setScreen("message")
      },
    },
    {
      title: "projets",
      icon: <FontAwesomeIcon icon={faGlobe} />,
      iconColor: "#fff",
      iconBgColor:
        "repeating-linear-gradient(201deg, #607d8b, rgb(44, 44, 52) 100px)",
      onClick: () => {
        alert("Projets is unavailable for now. You can play Pacman🙃");
        // setScreen("message")
      },
    },
    {
      title: "pacman",
      icon: <img src={Packman} alt="" />,
      iconColor: "#fff",
      iconBgColor:
        "repeating-linear-gradient(201deg, #333333, rgb(44, 44, 52) 100px)",
      onClick: () => {
        setScreen("pacman");
      },
    },
  ];

  return (
    <div
      className="home-screen"
      style={{
        backgroundImage: `linear-gradient(
      40deg,
      rgba(0, 0, 0, 0.623),
      #00000094,
      rgba(0, 0, 0, 0.572)
    ), url(${HomeImage})`,
      }}
    >
      <Statusbar color="#fff" />

      <div className="app-list">
        {apps.map((item, index) => {
          return (
            <div key={index} className="app-item" onClick={item.onClick}>
              <div
                className="icon"
                style={{
                  background: item.iconBgColor,
                  color: item.iconColor,
                }}
              >
                {item.icon}
              </div>
              <span>{item.title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Home;
