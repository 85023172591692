import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

const Control = ({ isReady, toggleDevice, devicePowered }) => {
  return (
    <div className="phone-control">
      <div
        className="phone-play"
        style={{ display: isReady && !devicePowered ? "flex" : "none" }}
        onClick={() => {
          devicePowered ? toggleDevice(false) : toggleDevice(true);
        }}
      >
        <button className="play-btn">
          <FontAwesomeIcon icon={faPowerOff} />
        </button>
        <div className="play-overlay"></div>
      </div>
    </div>
  );
};

export { Control };
