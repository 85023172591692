import React from "react";
import IMGONE from "../assets/images/Saly-38.png";
import IMGTWO from "../assets/images/Saly-39.png";

const Home = ({ exploreMe, contactMe }) => {
  return (
    <div id="home-section" className="hero">
      <div className="home-slider owl-carousel">
        <div className="slider-item">
          <div className="overlay"></div>
          <div className="container-fluid px-md-0">
            <div
              className="row d-md-flex no-gutters slider-text align-items-end justify-content-end"
              data-scrollax-parent="true"
            >
              <div
                className="mobile-sm-hide one-third order-md-last img"
                style={{
                  backgroundImage: `url(${IMGONE})`,
                  backgroundSize: "contain",
                }}
              >
                <div className="overlay"></div>
                <div className="overlay-1"></div>
              </div>
              <div
                className="one-forth d-flex  align-items-center ftco-animate"
                data-scrollax=" properties: { translateY: '70%' }"
              >
                <div className="text">
                  <span className="subheading">Hello! I Am Pilgrim</span>
                  <h1 className="mb-4 mt-3">
                    <span>UI/UX</span> Web &amp; Mobile Development
                  </h1>
                  <p>
                    <a
                      onClick={() => exploreMe()}
                      href
                      className="btn btn-primary btn-outline-primary bounce mr-2"
                    >
                      Explore Me
                    </a>{" "}
                    <a
                      onClick={() => contactMe()}
                      href
                      // target="blank"
                      // href="mailto:pilgrim.neu@gmail.com"
                      className="btn btn-primary"
                    >
                      Hire Me
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slider-item">
          <div className="overlay"></div>
          <div className="container-fluid px-md-0">
            <div
              className="row d-flex no-gutters slider-text align-items-end justify-content-end"
              data-scrollax-parent="true"
            >
              <div
                className="mobile-sm-hide one-third order-md-last img"
                style={{
                  backgroundImage: `url(${IMGTWO})`,
                  backgroundSize: "contain",
                }}
              >
                <div className="overlay"></div>
                <div className="overlay-1"></div>
              </div>
              <div
                className="one-forth d-flex align-items-center ftco-animate"
                data-scrollax=" properties: { translateY: '70%' }"
              >
                <div className="text">
                  <span className="subheading">
                    I Design &amp; Build Brands
                  </span>
                  <h1 className="mb-4 mt-3">
                    Hi, I am <span>GIDEON</span> the brain behind Pilgrim.
                  </h1>
                  <p>
                    <a
                      onClick={() => exploreMe()}
                      href
                      className="btn btn-primary btn-outline-primary bounce mr-2"
                    >
                      Explore Me
                    </a>{" "}
                    <a
                      onClick={() => contactMe()}
                      href
                      // target="blank"
                      // href="mailto:pilgrim.neu@gmail.com"
                      className="btn btn-primary"
                    >
                      Hire Me
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Home };
